@import '../../../components/Sass/Font';
@import '../../../components/Sass/Color';
@import '../../../components/Sass/InputBox';

.login-page {
    height: 100vh;
    display: flex;
    align-items: center;

    .box {
        margin: auto;
        width: 491px;
        height: 403px;

        .logo200px {
            width: 200px;
            height: 200px;
            margin-bottom: 20px;
        }

        .form-login{
            .input-login {
                // @extend %input-box-shared-enabled;
                box-shadow: 0px 2px 20px rgba(3, 35, 23, 0.0503198);
                @include font($font-family-first, $font-weight-400, $font-size-14, $color-ninth);
                width: 100%;
            }
        }
    }
}

