@import './Font';
@import './Color';

%input-box {
    width: auto;
    height: 41px;
    padding: 10px 20px;
    border: 0px;
    border-radius: 10px;
    @include font($font-family-first, $font-weight-400, $font-size-14, $color-eighth);
    text-align: left;

    .react-date-picker__wrapper {
        border: 0;
    }
}

.input-box-enabled {
    @extend %input-box;
    background: $color-fifth;
}

.input-box-disabled {
    @extend %input-box;
    background: #E8E8E8;
}