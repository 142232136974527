@import '../Sass/Font';
@import '../Sass/Color';

.sidebar {
    width: 270px;
    background: $color-fifth;

    .sidebar-content {

        .logo100px {
            width: 100px;
            height: 100px;
        }

        .logo60px {
            width: 60px;
            height: 60px;
        }

        %sidebar-menu-shared {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px;
            width: 100%;
            height: 41px;
            text-decoration: none;
            // cursor: pointer;

            .sidebar-menu-icon {
                width: 20px;
                height: 20px;
            }

            .sidebar-menu-text {
                margin-left: 10px;
                margin-bottom: 0px;
            }
        }

        .sidebar-menu-active {
            @extend %sidebar-menu-shared;
            @include font($font-family-first, $font-weight-600, $font-size-14, $color-eighth);
            background: $color-sixth;
        }

        .sidebar-menu {
            @extend %sidebar-menu-shared;
        }

        .sidebar-title {
            width: 100%;

            @include font($font-family-first, $font-weight-600, $font-size-14, $color-first);
            text-align: left;
            margin-bottom: 0px;
        }
    }
}