@import '../../../components/Sass/Font';
@import '../../../components/Sass/Color';

.box-kaders {

    .box-kader {

        .title-kader {
            width: 150px;
            @include font($font-family-first, $font-weight-400, $font-size-18, $color-eighth);
        }

        .title-kader-mobile {
            width: 80px;
            @include font($font-family-first, $font-weight-400, $font-size-10, $color-eighth);
        }
    
        .nama-kader {
            width: 220px;
            @include font($font-family-first, $font-weight-400, $font-size-18, $color-eighth);
        }

        .nama-kader-mobile {
            width: 80px;
            @include font($font-family-first, $font-weight-400, $font-size-10, $color-eighth);
        }
    
        .nomor-kader {
            width: 240px;
            @include font($font-family-first, $font-weight-400, $font-size-18, $color-eighth);
        }

        .nomor-kader-mobile {
            width: auto;
            @include font($font-family-first, $font-weight-400, $font-size-10, $color-eighth);
        }
    }
}
