@import '../../../components/Sass/Font';
@import '../../../components/Sass/Color';
@import '../../../components/Sass/InputBox';

.button-register-patient {
    box-shadow: 0px 2px 20px rgba(3, 35, 23, 0.0503198);
    border-radius: 10px;

    .button-register-patient-icon {
        width: 18px;
        height: 18px;
    }
}

.modal-custom-sizing {
    width: 65% !important;
    min-width: 700px !important;
    max-width: none!important;
}

.modal-content {
    background-color: #F8F8F8;

    .btn-edit-data {
        @include font($font-family-first, $font-weight-400, $font-size-18, $color-first);
        border: 0;
        background-color: transparent;
    }
    
    .btn-edit-data:hover {
        color: $color-seventh;
    }
}