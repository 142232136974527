$color-first: #00AE84;
$color-second: #38BCA0;
$color-third: #67C7AE;
$color-fourth: #A0D7C8;
$color-fifth: #FFFFFF;
$color-sixth: #F5FEFB;
$color-seventh: #D7E7E2;
$color-eighth: #3C4743;
$color-ninth: #70897F;

$color-guide: #00AE84, #38BCA0, #67C7AE, #A0D7C8, #FFFFFF, #F5FEFB, #D7E7E2, #3C4743, #70897F;
$counter: 1;
@each $color in $color-guide {

  .text-color-#{$counter} {
    color: $color !important;
  }
  .bg-color-#{$counter} {
    background-color: $color !important;
  }
  $counter: $counter + 1;
}