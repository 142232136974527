@import './Font';
@import './Color';

* {
    box-sizing: border-box;
    margin: 0;
}

.sidebar-and-content {
    display: flex;
    
    .contents {
        padding-left: 300px;

        .contents-box {
            margin-bottom: 30px;

            .content-title {
                @include font($font-family-first, $font-weight-600, $font-size-18, $color-eighth);
                margin-bottom: 35px;
                text-align: left;
            }        

            .content-profile {
                margin-top: 22px
            }
        }
    }
}

%btn-shared {
    width: 100%;
    height: 41px;
    padding: 10px 20px;
    box-shadow: 0px 2px 20px rgba(3, 35, 23, 0.0503198);
    border-radius: 10px;
}

.btn-first {
    @extend %btn-shared;
    background: #00AE84;
    border: 0px;

    @include font($font-family-first, $font-weight-400, $font-size-14, $color-fifth);
}

.btn-second {
    @extend %btn-shared;
    background: transparent;
    border: 2px solid #00AE84;

    @include font($font-family-first, $font-weight-400, $font-size-14, $color-first);
}

.mb-10 {
    margin-bottom: 10px !important;
}

.input-without-stepper {
    appearance: none !important;
    -moz-appearance: textfield !important;
}

.input-without-stepper::-webkit-outer-spin-button,
.input-without-stepper::-webkit-inner-spin-button {
    appearance: none !important;
    -webkit-appearance: none;
    margin: 0;
}

select:enabled {
    background-image: url('../../Assets/Images/select-option.svg') !important;
    background-repeat: no-repeat !important;
    background-position: right 15px center !important;
}

input[type="checkbox"]:checked {
    background-color: $color-first;
    border: 0px;
}

.contents-box-inline {

    .content-search-inline {
        width: 298px;
    }
}

.patient-filter {
    %select-patient-filter-shared {
        height: 38px;
        border: 0px;
    }

    .select-patient-filter {
        width: 161px;
        @extend %select-patient-filter-shared;
    }    

    .select-patient-filter-mobile {
        width: 140px;
        @extend %select-patient-filter-shared;
    }    
}





