@import '../Sass/Font';
@import '../Sass/Color';

.clickable-table-row {
    cursor: pointer;
}

.table-responsive {
    white-space: nowrap;
}

%table-th-td-shared {
    border-bottom-width: 10px;
    border-color: #F8F8F8;
    padding: 10px 20px;
    text-align: left;
    vertical-align: middle;
}

.table>:not(caption)>*>th {
    @extend %table-th-td-shared;
    background-color: $color-sixth;
    @include font($font-family-first, $font-weight-400, $font-size-12, $color-ninth);
}

.table>:not(caption)>*>td {
    @extend %table-th-td-shared;
    background-color: $color-fifth;
    @include font($font-family-first, $font-weight-400, $font-size-12, $color-eighth);
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    .pagination-list {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        margin: 0px !important;
        padding: 0px !important;

        .container-previous, .container-next {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            padding: 0px 24px
        }

        .pagination-previous, .pagination-next {
            @include font($font-family-first, $font-weight-400, $font-size-12, $color-first);
            text-decoration: none;
            border: 0px !important;
        }

        .pagination-link {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;

            width: 50px;
            height: 38px;
            border: 1px solid #00AE84;

            text-decoration: none;
            @include font($font-family-first, $font-weight-400, $font-size-12, $color-first);
        }

        .is-active {
            background: #00AE84;
            @include font($font-family-first, $font-weight-400, $font-size-12, $color-fifth);
        }

        .is-disabled {
            background: transparent;
        }

        .pagination-break {
            @include font($font-family-first, $font-weight-400, $font-size-12, $color-first);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 24px
        }
    }
}