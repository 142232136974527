$font-family-first: 'Poppins';

$font-weight-400: 400;
$font-weight-600: 600;
$font-weight-700: 700;

$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-32: 32px;

@mixin font($font-family, $font-weight, $font-size, $font-color) {
    font-family: $font-family;
    font-style: normal;
    font-size: $font-size;
    font-weight: $font-weight;
    color: $font-color;
}

.font-poppins {
    font-family: 'Poppins' !important;
}

$font_weight_list: 400, 600, 700;

@each $font_weight in $font_weight_list {
  .font-weight-#{$font_weight} {
    font-weight: $font_weight !important;
  }
}

$font_size_list: 12, 14, 18, 24, 32;

@each $font_size in $font_size_list {
  .font-size-#{$font_size} {
    font-size: #{$font_size}px !important;
  }
}