@import '../../components/Sass/Font';
@import '../../components/Sass/Color';

.content-section.dekstop {
    padding: 30px;
    width: 306px;
    height: 108px;
    background: $color-fifth;
    box-shadow: 0px 2px 20px rgba(3, 35, 23, 0.0503198);
    border-radius: 20px;
}

.content-section.mobile {
    padding: 20px 14px;
    width: 154px;
    height: 85px;
    background: $color-fifth;
    box-shadow: 0px 2px 20px rgba(3, 35, 23, 0.0503198);
    border-radius: 20px;
}