@import '../Sass/Font';
@import '../Sass/Color';

.search-group {
    height: 38px;
    box-shadow: 0px 2px 20px rgba(3, 35, 23, 0.0503198);
    border-radius: 10px;

    span .search-icon {
        padding: 10px 20px;
    }
}

