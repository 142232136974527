@import '../Sass/Color';

.loading-full-screen-bg {
    background: rgba(60, 71, 67, 0.5);
}

.color-spinner {
    border-left-color: $color-eighth;
    border-top-color: $color-eighth;
    border-bottom-color: $color-eighth;
}

.big-spinner {
    width: 3rem; 
    height: 3rem;
}