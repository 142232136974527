@import '../Sass/Font';
@import '../Sass/Color';
@import '../Sass/InputBox';

.container-form-title {
    @include font($font-family-first, $font-weight-400, $font-size-18, $color-eighth);
    margin-bottom: 24px;
}

.content-form-body-width {
    width: 230px !important;
}

.form-check-label {
    @include font($font-family-first, $font-weight-400, $font-size-18, $color-eighth);
}

.form-check-input {
    width: 27px;
    height: 27px;
}

.input-disabled {
    // @extend %input-box-disabled-shared;

    .detail-age {
        color: $color-ninth;
    }
}

%input-group-text-shared {
    width: auto;
    height: 41px;
    padding: 10px 20px;
    border: 0px;
    @include font($font-family-first, $font-weight-400, $font-size-14, $color-eighth);
    text-align: left;
}

.input-group-text-enabled {
    @extend %input-group-text-shared;
    background: $color-fifth;
}

.input-group-text-disabled {
    @extend %input-group-text-shared;
    background: #E8E8E8;
}

.file-name {
    @include font($font-family-first, $font-weight-600, $font-size-12, $color-eighth);
}